<template>
  <div>
    <v-container>
      <Table />      
    </v-container>
  </div>
</template>

<script>
export default {
  components: {
    Table: () => import("@/components/table"),
  },
};
</script>